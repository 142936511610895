<template>
  <section class="all-templates-view">
    <el-row type="flex" align="center">
      <el-col :span="22">
        <el-col v-if="getIsMobile" :xl="8" :lg="8" :md="12" :sm="12" :xs="12">
          <h3 class="title fw-normal text-dark mb-0 ls-05" :style="getIsMobile ? 'margin-top:10px;margin-left:2px' : ''">All WorkFlow </h3>
        </el-col> 
      
        <div class="top-board mb-1">
          <el-col v-if="!getIsMobile" :xl="8" :lg="8" :md="6" :sm="12" :xs="12">
          <div>
            <h3
              class="title fw-normal text-dark mb-0 ls-05"
              v-if="
                this.$route.query &&
                this.$route.query.group &&
                getSingleEntityGroupData &&
                getSingleEntityGroupData.name
              "
            >
              {{ getSingleEntityGroupData.name }}
            </h3>
            <h3 v-else class="fw-medium blue-dark fs-6 mt-05 ml-05">
              All Workflows 
            </h3>
          </div>
          </el-col>
          
          <div class="actions-wrapper">
            <div style="text-align: right" class="search-input">
              <el-col :xl="6" :lg="6" :md="12" :sm="12" :xs="12">
              <el-input
                class="search"
                placeholder="Search"
                prefix-icon="el-icon-search"
                size="small"
                v-model="search_string"
                clearable
                :style="getIsMobile ? 'width:110px;margin-top:35px' : 'width:170px;'"
              >
              </el-input>
             </el-col>
            
            </div>
            <div style="text-align: right" class="status-dd">
              <el-select
                v-model="selectedStatus"
                filterable
                clearable
                class="select"
                placeholder="Select Status"
                @change="loadData"
                :style="getIsMobile ? 'width:100px;margin-top:35px;margin-left:10px' : 'width:170px;margin-left:11px'"
              >
                <el-option
                  v-for="(status, index) in statusOption"
                  :key="index"
                  :value="status.value"
                  :label="status.name"
                ></el-option>
              </el-select>
            
            </div>
            <div
              style="text-align: right"
              class="create-btn"
              v-if="this.checkPermissions('createWorkflow')"
            >
              <el-button
                :style="getIsMobile ? '' : 'width:170px;'"
                class="create-btn px-4 ml-2 scaling-button"
                type="primary"
                @click="openNewWorkflow"
              >
              <span class="create-workflow">
              <i class="fa fa-plus-square-o"></i>
                {{ getIsMobile ? "" : "Create Workflow" }}
              </span>
              </el-button>
            </div>
          </div>
        </div>
        <div
          v-loading="configureLoading"
          :element-loading-text="loadingText"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="workflows-table"
        >
          <data-tables-server
            :fullscreen="true"
            :total="total"
            :data="allWorkflows"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            style="width: 100%"
            @query-change="loadData"
            @sort-change="sortChange"
          >
            <el-table-column
              label="Name"
              id="name"
              prop="title"
              :width="getIsMobile ? 150 : 250"
              fixed="left"
              sortable="custom"
              ><template slot-scope="scope">
                <!-- <div>
                <a slot="reference" class="doc-title" @click="onEdit(scope.row)">
                  {{ scope.row.name | truncate(15, "...") }}
                </a>
                </div> -->
                <div class="icon-text">
                  <template v-if="scope.row.name">
                    <el-popover
                      width="200"
                      trigger="hover"
                      :content="scope.row.name"
                    >
                      <p
                        slot="reference"
                        class="doc-title"
                        @click="onEdit(scope.row)"
                        style="cursor: pointer"
                      >
                        {{ scope.row.name | truncate(15, "...") }}
                      </p>
                    </el-popover>
                    <span class="mt-3">
                      <p class="doc-sub">
                        Created by: {{ getCreatedUser(scope.row) }}
                      </p>
                    </span>
                  </template>
                </div>
              </template></el-table-column
            >
            <el-table-column
              label="Actions"
              prop="action"
              class="action"
              :width="getIsMobile ? 160 : 180"
            >
              <template slot-scope="scope">
                <ul class="action-buttons hover-buttons">
                  <el-tooltip content="Send Workflow">
                    <li>
                      <el-button
                        v-if="scope.row.status == 'ACTIVE'"
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="onSend(scope.row)"
                      >
                        <i class="el-icon-position"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <li>
                    <el-dropdown>
                      <el-button size="mini" class="el-dropdown-link px-2">
                        Actions
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <a
                          v-if="scope.row.status == 'ACTIVE'"
                          @click="onEdit(scope.row)"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-edit"></i>Edit</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="scope.row.status == 'ACTIVE'"
                          @click="goToWorkflow(scope.row)"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-files"></i> View Workflow
                            Data</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="
                            (scope.row.status == 'ACTIVE' &&
                              scope.row.user_id == authid) ||
                            (scope.row.company_id &&
                              getActiveWorkspace &&
                              getActiveWorkspace.company_id &&
                              scope.row.company_id ==
                                getActiveWorkspace.company_id)
                          "
                          @click="OpenRenameModal(scope.row)"
                        >
                          <el-dropdown-item>
                            <i class="el-icon-edit-outline"></i>
                            Rename</el-dropdown-item
                          >
                        </a>

                        <a
                          @click="makeInactive(scope.row)"
                          v-if="
                            scope.row.user_id == authid ||
                            (scope.row.company_id &&
                              getActiveWorkspace &&
                              getActiveWorkspace.company_id &&
                              scope.row.company_id ==
                                getActiveWorkspace.company_id)
                          "
                        >
                          <el-dropdown-item>
                            <i
                              v-if="scope.row.status == 'ACTIVE'"
                              class="el-icon-document-delete"
                            ></i>
                            <i v-else class="el-icon-document-checked"></i>
                            {{
                              scope.row.status == "ACTIVE"
                                ? "Inactive"
                                : "Active"
                            }}</el-dropdown-item
                          >
                        </a>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </ul>
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              id="status"
              prop="title"
              sortable="custom"
              :width="getIsMobile ? 100 : 250"
            >
              <template slot-scope="scope" id="category">
                <div class="d-flex activestatus">
                  <div
                    :class="scope.row.status.toLowerCase() + ' circle'"
                  ></div>
                  <div>
                    <p v-if="scope.row.status == 'ACTIVE'">Active</p>
                    <p v-else-if="scope.row.status == 'INACTIVE'">Inactive</p>
                  </div>
                </div>
              </template></el-table-column
            >
            <el-table-column
              prop="forms"
              label="Workflows Created"
              :width="getIsMobile ? 160 : 180 "
            >
              <template slot-scope="scope">
                <template>
                  <el-tag
                    size="mini"
                    class="mr-1"
                    @click="
                      gotoLinkedDocuments(
                        scope.row,
                        scope.row._id,
                        'DRAFT',
                        scope.row.name
                      )
                    "
                  >
                    <el-tooltip placeholder="top-start" content="Draft">
                      <span class="fs-8">
                        {{ getLikendCount(scope.row._id) }}
                      </span>
                    </el-tooltip>
                  </el-tag>
                  <el-tag
                    type="warning"
                    size="mini"
                    class="mr-1"
                    @click="
                      gotoLinkedDocuments(
                        scope.row,
                        scope.row._id,
                        'IN-PROGRESS',
                        scope.row.name
                      )
                    "
                  >
                    <el-tooltip placeholder="top-start" content="In Progress">
                      <span class="fs-8">
                        {{ getInprogressCount(scope.row._id) }}
                      </span>
                    </el-tooltip>
                  </el-tag>
                  <el-tag
                    type="success"
                    size="mini"
                    @click="
                      gotoLinkedDocuments(
                        scope.row,
                        scope.row._id,
                        'COMPLETED',
                        scope.row.name
                      )
                    "
                  >
                    <el-tooltip placeholder="top-start" content="Completed">
                      <span class="fs-8">
                        {{ getCompletedCount(scope.row._id) }}
                      </span>
                    </el-tooltip>
                  </el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              label="Last Modified"
              prop="updated_at"
              sortable="custom"
              fixed="right"
              :width="getIsMobile ? 200 : 200"
              ><template slot-scope="scope">{{
                scope.row.updated_at | globalDateTimeFormat
              }}</template></el-table-column
            >
          </data-tables-server>
        </div>
      </el-col>
      <dialog-component
        :title="'Workflow Name'"
        :visible="newWorkflowModal"
        class="doc-modal create-workflow-popup"
        v-loading="modalLoading"
        :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        @before-close="destroyCreateModal"
      >
        <span>
          <label>Name :</label>
          <el-input
            type="text"
            placeholder="Title"
            v-model="workflowName"
          ></el-input>
          <!-- <p class="error" v-if="getError">
          {{ getError }}
        </p> -->
        </span>
        <template v-if="!this.$route.query.group">
          <span> <label>Groups</label></span>
          <br />
          <span>
            <el-select
              v-model="entityGroupsData"
              placeholder="Select Group"
              multiple
              filterable
              collapse-tags
            >
              <el-option
                v-for="(item, index) of getAllEntityGroupsData.data"
                v-bind:key="index"
                :label="item.name"
                :value="item._id"
              ></el-option>
            </el-select>
            <el-tooltip content="Add New Group" placement="top" effect="light">
              <i class="el-icon-circle-plus-outline" @click="addGroupPopUP"></i>
            </el-tooltip>
          </span>
        </template>

        <span slot="footer" class="dialog-footer">
        <el-button @click="destroyCreateModal">Cancel</el-button>
          <el-button
            type="primary"
            :disabled="!workflowName.trim() && !entityGroupsData.length"
            @click="createWorkflow"
            >Confirm</el-button
          >
        </span>
      </dialog-component>
      <dialog-component
        class="rename-workflow-popup"
        :title="'Rename Workflow'"
        :visible="RenameModal"
        @before-close="RenameModal=false"
        v-draggable
      >
        <label>Title :</label>
        <el-input
          type="text"
          placeholder="Title"
          v-model="workflowName"
        ></el-input>
        <!-- <p class="error" v-if="getError">
          {{ getError }}
        </p>
     -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="RenameModal = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="renameTemplate"
            :disabled="!workflowName.trim()"
            >Confirm</el-button
          >
        </span>
      </dialog-component>
      <el-dialog
        :title="workflowsText"
        :visible.sync="selectedWorkflowModal"
        :width="getIsMobile ? '100%' : '60%'"
        :before-close="close"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div>
          <div v-if="selectedWorkflows.length">
            <el-row>
              <el-col :span="8"><h4>Name</h4></el-col>
              <el-col :span="6"><h4>Status</h4></el-col>
              <el-col :span="10"><h4>Completed/Total documents</h4></el-col>
            </el-row>
            <el-scrollbar wrap-style="max-height: 400px;">
              <el-row
                v-for="(flow, i) in selectedWorkflows"
                :key="i"
                class="document-box"
              >
                <el-col :span="8">
                  <div>
                    <p>{{ flow.name }}</p>
                  </div></el-col
                >
                <el-col :span="6" style="display: flex">
                  <div
                    :class="flow.workflow_status.toLowerCase() + ' circle'"
                  ></div>
                  <div>
                    <p>{{ flow.workflow_status }}</p>
                  </div>
                </el-col>
                <el-col :span="4" :offset="4" style="display: flex">
                  <div>
                    <p>
                      {{
                        flow.completed_documents + "/" + flow.total_documents
                      }}
                    </p>
                  </div>
                </el-col>
              </el-row>
            </el-scrollbar>
          </div>
          <div v-else style="text-align: center">
            <p>No data</p>
          </div>
        </div>
      </el-dialog>
    </el-row>

    <dialog-component
      :title="'Add New Group'"
      :visible="showAddGroupDialog"
      :containerWidth="getIsMobile ? '100%' : '35%'"
      @before-close="showAddGroupDialog=false"
    >
      <el-form>
        <div v-loading="loadingSaveGroup">
          <el-form-item class="mb-10" label="Group Title">
            <el-input
              type="text"
              v-model="groupName"
              placeholder="Enter Title"
            ></el-input>
            <p
              class="error"
              v-if="!groupName || !groupName.length || groupName === ' '"
            >
              * Please add group title *
            </p>
          </el-form-item>
        </div>
      </el-form>
          <span slot="footer">
            <el-button @click="showAddGroupDialog = false">Cancel</el-button>
            <el-button
              type="primary"
              @click="saveGroup"
              :disabled="!groupName || !groupName.trim() "
              class="px-20"
              ><i class="el-icon-check"></i> Save Group
            </el-button>
          </span>
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";

import NavigationHelper from "@/mixins/navigationHelper";
export default {
  name: "workflows-templateWorkflowList",
  // metaInfo: {
  //   title: "eSigns - Workflows",
  //   meta: [
  //     { charset: "utf-8" },
  //     {
  //       name: "Workflows",
  //       content:
  //         "eSigns understands your business process better, faster, and quicker than the rest of the world to ensure hassle-free and paperless workflow management.",
  //     },
  //   ],
  //   //  titleTemplate: '%s | eSigns understands your business process better, faster, and quicker than the rest of the world to ensure hassle-free and paperless workflow management.'
  // },
  data() {
    return {
      search_string: "",
      configureLoading: false,
      loadingText: "",
      total: 0,
      activeStep: 0,
      currentPage: 1,
      pageSize: 0,
      page: 1,
      newWorkflowModal: false,
      workflowName: "",
      selectedStatus: "ACTIVE",
      statusOption: [
        { name: "Active", value: "ACTIVE" },
        { name: "Inactive", value: "INACTIVE" },
        // { name: "Archived", value: "ARCHIVED"}
      ],
      modalData: {
        id: "",
        name: "",
      },
      modalLoading: false,
      RenameModal: false,
      allWorkflows: [],
      order_by: null,
      order_type: null,
      selectedWorkflows: [],
      workflowsText: "",
      selectedWorkflowModal: false,
      selectedRoute: "",
      showAddGroupDialog: false,
      groupName: "",
      loadingSaveGroup: false,
      entityGroupsData: [],
    };
  },

  mixins: [NavigationHelper],
  computed: {
    ...mapGetters("entityGroups", ["getSingleEntityGroupData"]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("configurableDocuments", [
      "getConfigurableDocumentStatusUpdateStatus",
    ]),
    ...mapGetters("templateWorkflow", [
      "getAllWorkflows",
      "getWorkflowResendStatus",
      "getWorkflowCreatedStatus",
      "getWorkflowCreatedData",
      "getWorkflowDataUpdateStatus",
      "getWorkflowsCountById",
      "getWorkflowDataCreatedStatus",
      "getWorkflowDataCreatedData",
      "getActiveInactiveWorkflowStatus",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("entityGroups", [
      "getAllEntityGroupsData",
      "getSingleEntityGroupData",
      "getEntityGroupErrors",
      "getError",
    ]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  async mounted() {
    this.authid =
      this.getAuthenticatedUser && this.getAuthenticatedUser._id
        ? this.getAuthenticatedUser._id
        : null;
    if (
      this.$router &&
      this.$router.currentRoute &&
      this.$router.currentRoute.query &&
      this.$router.currentRoute.query.type
    ) {
      this.selectedRoute = this.$router.currentRoute.query.type;
    }
    await this.fetchWorkflows();
    await this.fetchCompanyGroups();
  },
  methods: {
    /*  ***Fetch Groups  start  **** */
    async fetchCompanyGroups() {
      try {
        await this.$store.dispatch("entityGroups/fetEntityGroupsData", {
          get_all: true,
        });
      } catch (e) {
        console.log("fetchCompanyGroups is Error",e);
      }
    },
    /*  ***Fetch Groups  end  **** */
    async addGroupPopUP() {
      this.showAddGroupDialog = true;

      await this.$store.dispatch("entityGroups/resetErrors");
    },
    async saveGroup() {
      this.loadingSaveGroup = true;
      await this.$store.dispatch("entityGroups/addEntityGroup", {
        name: this.groupName,
        description: "",
        entities: [],
      });
      if (
        this.getSingleEntityGroupData &&
        this.getSingleEntityGroupData.success
      ) {
        this.fetchCompanyGroups();
        this.loadingSaveGroup = false;
        this.groupName = "";
        this.showAddGroupDialog = false;
        this.entityGroupsData.push(this.getSingleEntityGroupData.data._id);
      } else if (this.getError && !this.getError.success) {
        this.loadingSaveGroup = false;
        let msg= this.getError&& this.getError.message?this.getError.message:"Something went wrong"
        this.$message.warning(msg);
      }
    },
    getCreatedUser(data) {
      let name = "";
      if (
        data &&
        data.created_by &&
        data.created_by.first_name &&
        data.created_by.last_name
      ) {
        name = data.created_by.first_name + " " + data.created_by.last_name;
      } else {
        name = "-";
      }
      return name;
    },
    checkPermissions(permission) {
      if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    },
    async OpenRenameModal(data) {
      if (
        this.getAuthenticatedUser &&
        data.created_by &&
        data.created_by._id != this.getAuthenticatedUser._id &&
        !this.checkPermissions("renameWorkflow")
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for rename workflow. Please contact owner.",
        });
      } else {
        try {
          this.workflowName = data.name.trim();
          this.workflow_id = data._id;
          this.RenameModal = true;
        } catch (err) {
          console.log("OpenRenameModal Error",err);
        }
      }
    },
    async renameTemplate() {
      let params = {
        name: this.workflowName.trim(),
      };
      params.id = this.workflow_id;
      this.modalLoading = true;
      this.loadingText = "Loading...";
      await this.$store.dispatch("templateWorkflow/updateWorkFlowById", params);
      if (this.getWorkflowDataUpdateStatus) {
        this.RenameModal = false;
        this.modalLoading = false;
        this.$notify({
          title: "Success",
          message: "Workflow title updated successfully",
          type: "success",
        });
        this.fetchWorkflows();
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchWorkflows();
      }
    },
    async makeInactive(data) {
      if (
        this.getAuthenticatedUser &&
        data.created_by &&
        data.created_by._id != this.getAuthenticatedUser._id &&
        !this.checkPermissions("inactiveWorkflow")
      ) {
        this.$notify.error({
          title: "Error",
          message: "Permission denied. Please contact owner.",
        });
      } else {
        try {
          this.modalLoading = true;
          this.loadingText = "Loading...";
          let workflow_id = data._id;
          let params = {};
          if (data.status == "ACTIVE") {
            params.status = "INACTIVE";
          }
          if (data.status == "INACTIVE") {
            params.status = "ACTIVE";
          }
          params.id = workflow_id;
          await this.$store.dispatch(
            "templateWorkflow/UpdateActiveInactiveStatusOfWorkflow",
            params
          );
          if (this.getActiveInactiveWorkflowStatus) {
            this.modalLoading = false;
            this.$notify.success({
              title: "Success",
              message: "Workflow status updated successfully",
            });
          } else {
            this.modalLoading = false;
            this.errorNotify("Error in workflow status updating");
          }
          this.refreshDocument();
        } catch (err) {
          this.modalLoading = false;
          console.log("makeInactive Error",err);
        }
      }
    },
    refreshDocument() {
      window.location.reload();
    },
    close() {
      this.selectedWorkflows = [];
      this.selectedWorkflowModal = false;
    },
    getLikendCount(id) {
      let value = 0;
      if (this.getWorkflowsCountById && this.getWorkflowsCountById.length) {
        this.getWorkflowsCountById.forEach((doc) => {
          if (doc.workflow_id == id && doc.workflow_status == "DRAFT") {
            value = value + 1;
          }
        });
      }
      return value;
    },
    getCompletedCount(id) {
      let value = 0;
      if (this.getWorkflowsCountById && this.getWorkflowsCountById.length) {
        this.getWorkflowsCountById.forEach((doc) => {
          if (doc.workflow_id == id && doc.workflow_status == "COMPLETED") {
            value = value + 1;
          }
        });
      }
      return value;
    },
    getInprogressCount(id) {
      let value = 0;
      if (this.getWorkflowsCountById && this.getWorkflowsCountById.length) {
        this.getWorkflowsCountById.forEach((doc) => {
          if (doc.workflow_id == id && doc.workflow_status == "SENT") {
            value = value + 1;
          }
        });
      }
      return value;
    },
    async gotoLinkedDocuments(data,id, status) {
    if (
        this.getAuthenticatedUser &&
        data.created_by &&
        data.created_by._id != this.getAuthenticatedUser._id &&
        !this.checkPermissions("viewUserWorkflow")
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for view workflow data.Please contact owner.",
        });
      } else {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        name: "Workflows-Data",
        params: {
          workflow_id: id,
        },
        query: {
          type: status,
          ...query,
        },
      });
     }
    },
    async goToWorkflow(data) {
      if (
        this.getAuthenticatedUser &&
        data.created_by &&
        data.created_by._id != this.getAuthenticatedUser._id &&
        !this.checkPermissions("viewUserWorkflow")
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for view workflow data.Please contact owner.",
        });
      } else {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "Workflows-Data",
          params: {
            workflow_id: data._id,
          },
          query,
        });
      }
    },
    async onEdit(document) {
      if (
        this.getAuthenticatedUser &&
        document.created_by &&
        document.created_by._id != this.getAuthenticatedUser._id &&
        !this.checkPermissions("editWorkflow")
      ) {
        this.$notify.error({
          title: "Error",
          message: "Permission denied for workflow edit. Please contact owner",
        });
      } else {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "workflow-edit",
          params: {
            workflow_id: document._id,
          },
          query: {
            activeStep: 0,
            ...query,
          },
        });
      }
    },
    async resendWorkflowToPrimaryUser(data) {
      let params = {
        workflow_id: data._id,
        name: data.name,
        created_by: data.created_by,
        workflow_settings: data.workflow_settings,
      };

      await this.$store.dispatch(
        "templateWorkflow/resendWorkflowToPrimaryUser",
        params
      );
      if (this.getWorkflowResendStatus) {
        this.$notify({
          title: "Success",
          message: "Workflow resent successfully",
          type: "success",
        });
      }
    },
    async onSend(document) {
      if(!this.checkPermissions('sendWorkflow')){
        this.$notify.error({
          title:"Error",
          message:"Permission denied for sending workflow. Please contact owner.!"
        })
      }else{
        this.configureLoading = true;
      this.loadingText = "Sending workflow...";
      let params = {
        workflow_id: document._id,
        enforce_workflow_order: false,
        templates_used: document.templates_used,
        name: document.name,
        workflow_settings: document.workflow_settings,
        workflow_status: "DRAFT",
      };
      await this.$store.dispatch(
        "templateWorkflow/createNewWorkflowData",
        params
      );
      if (
        this.getWorkflowDataCreatedStatus &&
        this.getWorkflowDataCreatedData &&
        this.getWorkflowDataCreatedData._id
      ) {
        this.$notify({
          title: "Success",
          message: "Workflow data created successfully",
          type: "success",
        });
        this.configureLoading = false;
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "workflow-send",
          params: {
            workflow_data_id: this.getWorkflowDataCreatedData._id,
          },
          query: {
            activeStep: 1,
            ...query,
          },
        });
      } else {
        this.$notify({
          title: "Error",
          message: "Error in sending workflow",
          type: "error",
        });
        this.configureLoading = false;
      }
      }
      
    },
    destroyCreateModal() {
      this.workflowName = "";
      this.newWorkflowModal = false;
    },
    openNewWorkflow() {
      if (!this.checkPermissions("createWorkflow")) {
        this.$notify.error({
          title: "Error",
          message: "Permission denied. Please contact owner",
        });
      } else {
        this.workflowName = "";
        this.newWorkflowModal = true;
      }
    },
    async createWorkflow() {
      if(!this.workflowName || this.workflowName.length == 0 || !this.workflowName.length){
        this.$message.error("Workflow name is required")
      }else{
      let params = {
        name: this.workflowName,
        groups: this.$route.query.group
          ? [this.$route.query.group]
          : this.entityGroupsData,
      };
      await this.$store.dispatch("templateWorkflow/createNewWorkflow", params);
      if (this.getWorkflowCreatedStatus) {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.replace({
          path: `/workflow/edit/${this.getWorkflowCreatedData._id}`,
          query,
        });
      }
      }
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchWorkflows();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchWorkflows();
      }
    },
    async fetchWorkflows() {
      try {
        this.configureLoading = true;
        this.loadingText = "Fetching workflows...";
        let params = {
          limit: this.pageSize > 0 ? this.pageSize : 10,
          page: this.page,
        };
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        if (this.selectedStatus) {
          params.status = this.selectedStatus;
        }
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if (this.$route.query.group && this.$route.query.group.length) {
          params.group = this.$route.query.group;
          await this.$store.dispatch(
            "entityGroups/fetEntityGroupDataById",
            this.$route.query.group
          );
        }
        if(this.getAuthenticatedUser &&this.getAuthenticatedUser.activeRole)
        {
          await this.$store.dispatch("roles/fetchRoleById",
          this.getAuthenticatedUser.activeRole.role_id,
        );
        if(this.getRoleById && this.getRoleById)
        {
            params.groups=this.getRoleById.allowed_groups
        }
          }
        await this.$store.dispatch("templateWorkflow/fetchWorkflows", params);
        if (this.getAllWorkflows) {
          this.allWorkflows =
            this.getAllWorkflows && this.getAllWorkflows.data
              ? this.getAllWorkflows.data
              : [];
          let workflowstIds = this.allWorkflows.flatMap((temp) => temp._id);
          this.total =
            this.getAllWorkflows && this.getAllWorkflows.total
              ? this.getAllWorkflows.total
              : 0;
          await this.getWorkflowCount(workflowstIds);
          this.configureLoading = false;
        } else {
          this.configureLoading = false;
        }
      } catch (err) {
        console.log("fetchWorkflows error",err);
      }
    },
    async getWorkflowCount(ids) {
      try {
        let params = {
          workflowIds: ids,
        };
        await this.$store.dispatch(
          "templateWorkflow/fetchWorkflowsCountById",
          params
        );
      } catch (err) {
        console.log("getWorkflowCount Error",err);
      }
    },
  },
  watch: {
    search_string: function (val) {
      this.search_string = val;
      this.fetchWorkflows();
    },
    selectedStatus: function (val) {
      this.selectedStatus = val;
      this.fetchWorkflows();
    },
    "$route.query.group"() {
      this.fetchWorkflows();
      this.fetchCompanyGroups();
    },
    "$route.query.key"() {
      this.fetchWorkflows();
      this.fetchCompanyGroups();
    },
  },
};
</script>
<style scoped lang="scss">
.doc-title {
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  color: #6c757d !important;
}
.doc-sub {
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  margin-top: 1px !important;
}
.activestatus {
  font-size: 14px;
  color: #6c757d !important;
}
.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;
  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }
  &.active::after {
    background: #08ad36;
  }
  &.inactive::after {
    background: #f562b3;
  }
  &.archived::after {
    background: #404040;
  }
  &.disable::after {
    background: #c4c4c4;
  }
  &.viewed::after {
    background: #2f80ed;
  }
  &.completed::after {
    background: #08ad36;
  }
  &.draft::after {
    background: #f19203;
  }
  &.sent::after {
    background: #2f80ed;
  }
  &.expired::after {
    background: #f03232;
  }
}
.el-tag {
  cursor: pointer;
  text-align: center;
  border-radius: 20px;
  padding: 4px;
  line-height: 1;
  width: 30px;
  height: auto;
  span {
    margin-left: -0.7em !important;
  }
}
.document-box {
  padding: 10px;
  display: flex;
  border-top: 1px solid #d3d8db;
  border-right: 1px solid #d3d8db;
  border-left: 1px solid #d3d8db;
}
.document-box:last-child {
  border-bottom: 1px solid #d3d8db;
}
// .icon-block {
//   display: grid;
//   grid-template-columns: 25px auto;
//   gap: 10px;
//   align-items: center;
//   .icon-text {
//     td {
//       border-bottom: none !important;
//       font-weight: 500;
//       color: #000;
//     }
//   }
// }

.top-board {
  .title {
    margin-bottom: 20px;
  }
  .actions-wrapper {
    display: flex;
    justify-content: end;
    & > div {
      &:first-child {
        margin-right: 15px;
      }
    }
    @media (max-width: 606px) {
      // flex-direction: column;
      & > div {
        margin-right: 5px !important;
        margin-left: 0px !important;
        &:first-child {
          // margin-right: 0px;
        }
        margin-bottom: 5px;
      }
      .search-input {
        width: 120px;
      }
      .status-dd {
        width: 120px;
      }
      .create-btn {
        width: 70px;
        margin-top: 19px;
        @media (max-width:500px){
          margin-top: 17px !important;
        }
        button {
          padding-left: 10px !important;
          padding-right: 10px !important;
          margin-left: 0px !important;
        }
      }
    }
  }
}

</style>
<style lang="scss">
.workflows-table{
  .el-table {
    position: relative;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    color: #606266;
    border-radius: 10px !important;
    box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
    margin-bottom: 2vh;
  }

  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #EBEEF5;
    background-color: var(--lighter-primary-color);
    height: 5vh !important;
    /* height: 5vh !important; */
    padding: 3px 5px !important;
  }

  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    -webkit-transition: background-color .25s ease;
    transition: background-color .25s ease;
    padding: 5px 10px !important;
  }
}

.rename-workflow-popup {
  & > .el-dialog {
    width: 430px !important;
    @media (max-width: 500px) {
      width: 310px !important;
    }
  }
}

.all-templates-view {
  .doc-modal {
    &.create-workflow-popup {
      & > .el-dialog {
        width: 430px !important;
        @media (max-width: 500px) {
          width: 310px !important;
        }
      }
    }
  }
}
</style>

<style>
.scaling-button {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s;
  height:32px !important;
}

.scaling-button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background-color: rgba(0, 0, 0, 0.1); /* Change this color as needed */
  transform: scale(0);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
}

.scaling-button:hover {
  transform: scale(1.03);
}

.scaling-button:hover::before {
  transform: scale(1);
  opacity: 1;
}
.create-workflow{
  position:relative;
  right:3px;
  bottom:3px;
}
.el-input--small .el-input__inner {
  margin-left: 10px !important;
}
.el-icon-search{
  margin-left: 15px;
}
.el-col-22 {
  width: 100% !important;
}
</style>
